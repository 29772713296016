import { ColouredVariant } from '../../components/Tag';
import colors from './colors';

const DEFAULT_CONVERSION_VALUE = 0.0625;

export const pxToRem = (pixel: number) => {
  const remSize = DEFAULT_CONVERSION_VALUE * pixel;
  // Round to decimals to avoid CSS errors from too many decimal places.
  return `${remSize.toFixed(4)}rem`;
};

const colorVariantMap: Record<ColouredVariant, string | null> = {
  info: colors.info,
  warning: colors.warning,
  success: colors.success,
  error: colors.error,
  white: colors.white,
  live: colors.green,
  win: colors.black,
  default: null,
};

export const colourFromVariant = (variant: ColouredVariant) => {
  return colorVariantMap[variant] ?? colors.gray500;
};
